<template>

<div class="main">

  <div class="row">
    <div class="column" v-for="(tabphoto, index) in tabphotos" :key="index">
      <div class="content">
        <img
          :src="tabphotos[index].hero" 
          style="width:100%;">
      </div>
    </div>
  </div>

</div>
</template>

<script>
  export default {
    name: 'NewphotoGalleryAbout',
    props: ['tabphotos', ],

    methods: {
      myphoto(myindex) {
        var temp;
        temp = 'photos/about/' + this.tabphotos[myindex].hero
        return temp;
      }
    },

  }
</script>

<style scoped>
 /* Center website */
.main {
  max-width: 2000px;
  margin: auto;
}

h1 {
  font-size: 50px;
  word-break: break-all;
}

.row {
  margin: 2px -16px;
}

/* Add padding BETWEEN each column */
.row,
.row > .column {
  padding: 5px;
}

/* Create four equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
}

/* Clear floats after rows */ 
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Content */
.content {
  background-color: white;
  padding: 2px;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 900px) {
  .column {
    width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}

</style>
